import React, { Component } from 'react'
import { graphql } from 'gatsby'
import propTypes from 'prop-types'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Img from 'gatsby-image'
import styles from '../pages/team.module.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
   faLongArrowAltLeft,
   faExternalLinkAlt,
   faCalendarPlus,
   faPaperPlane,
   faFileAlt,
} from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'

import TeamMember from '../components/teamMember'

let none = 'none'

class Employee extends Component {
   render() {
      const {
         name,
         slug,
         nickname,
         linkedIn,
         certifications,
         yearJoined,
         image,
         roleSpc,
         roleSm,
         hometown,
         education,
         communityInvolvement,
         interests,
         biography,
         resume,
      } = this.props.data.contentfulEmployee
      var splitName = name.split(' ')
      var splitNameLast = splitName[splitName.length - 1].toLowerCase()
      // If has extra word after last name
      var splitNameLast2 = splitName[splitName.length - 2]
         .replace(/,*[, ]*/g, '')
         .toLowerCase()
      if (splitNameLast.includes('.') || name.includes(', ')) {
         var lastName2 = splitNameLast2
      } else {
         var lastName1 = splitNameLast
      }
      // Prevent duplicate definitions
      var lastName = lastName1 ? lastName1 : lastName2
      const emailHref =
         'mailto:' +
         (lastName.toLowerCase() === 'chatfield'
            ? 'greg.chatfield'
            : name.charAt(0).toLowerCase() + lastName) +
         '@spcfinancial.com'
      return (
         <Layout>
            <SEO
               title={
                  name +
                  (certifications
                     ? ', ' +
                       certifications
                          .slice(0)
                          .reverse()
                          .map((certification) => certification)
                     : '')
               }
            />
            <div className={['page', styles.page__team_member].join(' ')}>
               <div className={['page--header', styles.page__header].join(' ')}>
                  <h1 className={['page--title', styles.page__title].join(' ')}>
                     Meet {nickname ? nickname : name.replace(/ .*/, '')}
                  </h1>
                  {/* <p className={['page--subheader', styles.page__subheader].join(' ')}>Our team comprises of multiple wealth management and tax-integrated advisors.<Link to="/legal/disclaimers#tax">*</Link></p> */}
               </div>
               <div className={styles.content}>
                  <div className={['sidebar', styles.sidebar].join(' ')}>
                     <Link
                        to="/team/"
                        className="button button--link button--primary button--large button--has-icon button--has-icon_left">
                        <FontAwesomeIcon icon={faLongArrowAltLeft} /> All Team
                        Members
                     </Link>
                     {image && (
                        <Img
                           fluid={image.fluid}
                           alt={image.title}
                           className={styles.image}
                        />
                     )}
                     <TeamMember
                        isBiographyPage="true"
                        margin="0"
                        width="calc(100% - (14px * 2) - (3px * 2))"
                        columns="100%"
                        name={name}
                        nickname={nickname}
                        slug={slug}
                        certifications={certifications}
                        roleSpc={roleSpc}
                        roleSm={roleSm}
                        hasBiography={biography.id ? true : false}
                        className={styles.team__member_header}
                     />
                  </div>
                  <div className={styles.biography}>
                     {biography.biography
                        .split('\n\n')
                        .map((content, index) => {
                           return <p key={index}>{content}</p>
                        })}
                  </div>
                  <div className={['sidebar', styles.sidebar].join(' ')}>
                     <div className={styles.inner}>
                        {yearJoined && (
                           <div className={styles.sidebar__group}>
                              <h6 className={styles.sidebar__header}>
                                 Joined Us
                              </h6>
                              <p className={styles.sidebar__group_content}>
                                 {yearJoined}
                              </p>
                           </div>
                        )}
                        {hometown && (
                           <div className={styles.sidebar__group}>
                              <h6 className={styles.sidebar__header}>
                                 Hometown
                              </h6>
                              <p className={styles.sidebar__group_content}>
                                 {hometown}
                              </p>
                           </div>
                        )}
                        {certifications && (
                           <div className={styles.sidebar__group}>
                              <h6 className={styles.sidebar__header}>
                                 Certifications
                              </h6>
                              <p
                                 className={[
                                    styles.sidebar__group_content,
                                    styles.sidebar__group_licenses,
                                 ].join(' ')}>
                                 {certifications &&
                                    certifications
                                       .slice(0)
                                       .reverse()
                                       .map((certification, index) => (
                                          <span key={index}>
                                             {certification}
                                          </span>
                                       ))}
                              </p>
                           </div>
                        )}
                        {education && (
                           <div className={styles.sidebar__group}>
                              <h6 className={styles.sidebar__header}>
                                 Education
                              </h6>
                              <p
                                 className={[
                                    styles.sidebar__group_content,
                                    styles.sidebar__group_education,
                                 ].join(' ')}>
                                 {education &&
                                    education.map((item, index) => (
                                       <span key={index}>{item}</span>
                                    ))}
                              </p>
                           </div>
                        )}
                        {communityInvolvement && (
                           <div className={styles.sidebar__group}>
                              <h6 className={styles.sidebar__header}>
                                 Community Involvement
                              </h6>
                              <p className={styles.sidebar__group_content}>
                                 {communityInvolvement}
                              </p>
                           </div>
                        )}
                        {interests && (
                           <div className={styles.sidebar__group}>
                              <h6 className={styles.sidebar__header}>
                                 Interests
                              </h6>
                              <p className={styles.sidebar__group_content}>
                                 {interests}
                              </p>
                           </div>
                        )}
                     </div>
                     <footer className={styles.sidebar__actions}>
                        {linkedIn && (
                           <div
                              className={[styles.action, styles.linkedin].join(
                                 ' '
                              )}>
                              <OutboundLink
                                 href={linkedIn}
                                 target="_blank"
                                 rel="noopener noreferrer"
                                 className="button button--white button--large button--full button--has-icon"
                                 aria-label={
                                    nickname
                                       ? nickname + "'s LinkedIn"
                                       : name.replace(/ .*/, '') + "'s"
                                 }>
                                 <span style={{ display: none }}>
                                    {nickname
                                       ? nickname + "'s "
                                       : name.replace(/ .*/, '') + ' '}
                                 </span>
                                 LinkedIn{' '}
                                 <FontAwesomeIcon icon={faExternalLinkAlt} />
                              </OutboundLink>
                           </div>
                        )}
                        {(slug === 'edward-sella' ||
                           slug === 'matthew-gilchrist' ||
                           slug === 'rory-mcglynn') && (
                           <div
                              className={[styles.action, styles.linkedin].join(
                                 ' '
                              )}>
                              <a
                                 href={
                                    '//calendly.com/spc-' +
                                    name.charAt(0).toLowerCase() +
                                    lastName
                                 }
                                 target="_blank"
                                 rel="noopener noreferrer"
                                 className="button button--secondary button--large button--full button--has-icon"
                                 aria-label={
                                    nickname
                                       ? nickname + "'s LinkedIn"
                                       : name.replace(/ .*/, '') + "'s"
                                 }>
                                 <span style={{ display: none }}>
                                    {nickname
                                       ? nickname + "'s "
                                       : name.replace(/ .*/, '') + ' '}
                                 </span>
                                 Make Appointment{' '}
                                 <FontAwesomeIcon icon={faCalendarPlus} />
                              </a>
                           </div>
                        )}
                        <div
                           className={[
                              styles.action,
                              styles.email__team_member,
                           ].join(' ')}>
                           <OutboundLink
                              href={emailHref}
                              target="_blank"
                              className="button button--primary button--large button--full button--has-icon">
                              Email{' '}
                              {nickname ? nickname : name.replace(/ .*/, '')}{' '}
                              <FontAwesomeIcon icon={faPaperPlane} />
                           </OutboundLink>
                        </div>
                        {resume && (
                           <div
                              className={[styles.action, styles.resume].join(
                                 ' '
                              )}>
                              <OutboundLink
                                 href={resume.file.url}
                                 target="_blank"
                                 className="button button--white button--large button--full button--has-icon">
                                 Resume
                                 <FontAwesomeIcon icon={faFileAlt} />
                              </OutboundLink>
                           </div>
                        )}
                     </footer>
                  </div>
               </div>
            </div>
         </Layout>
      )
   }
}

Employee.propTypes = {
   data: propTypes.object.isRequired,
}

export default Employee

export const pageQuery = graphql`
   query employeeQuery($slug: String!) {
      contentfulEmployee(slug: { eq: $slug }) {
         name
         slug
         createdAt(formatString: "MMMM DD, YYYY")
         nickname
         linkedIn
         certifications
         yearJoined
         image {
            title
            fluid(maxWidth: 500) {
               ...GatsbyContentfulFluid
               # ...GatsbyContentfulFluid_tracedSVG
            }
         }
         roleSpc
         roleSm
         teams
         hometown
         education
         communityInvolvement
         interests
         biography {
            biography
         }
         resume {
            file {
               url
            }
         }
      }
   }
`
